import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Skill Test: Max HS Walk (or Hold), 3 attempts`}</p>
    <p>{`Skill Test 2: Max Double Unders, 2 attempts`}</p>
    <p>{`Skill Test 3: Max Ring Muscle Ups (or Ring Dips), 1 attempt`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`1-Bar Pullover to Bar Muscle Up
 (`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=RT6qXjey3wA"
      }}>{`https://www.youtube.com/watch?v=RT6qXjey3wA`}</a>{`)`}</p>
    <p>{`20-Squats`}</p>
    <p>{`1-Rope Climb to 15′`}</p>
    <p>{`20-Situps`}</p>
    <p>{`1-Ring Muscle Up to 5 Ring Dips`}</p>
    <p>{`20-Back Extensions`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      